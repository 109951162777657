.App {
  background-color: #f5f5f5;
}

.get-in-touch-button {
  padding: 20px;
  font-size: 1.2em;
  color: #555;
  background-color: #efe9db;
  margin-bottom: 50px;
}

.get-in-touch-button:hover {
  background-color: #f9e9c4;
}

.julius-sans-one-regular {
  font-family: "Julius Sans One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poiret-one-regular {
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poiret-one-bold {
  font-family: "Poiret One", sans-serif;
  font-weight: 800;
  font-style: bold;
}


.rajdhani-light {
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.rajdhani-regular {
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rajdhani-medium {
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.rajdhani-semibold {
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.rajdhani-bold {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-style: normal;
}


.major-mono-display-regular {
  font-family: "Major Mono Display", monospace;
  font-weight: 400;
  font-style: normal;
}

.love-light-regular {
  font-family: "Love Light", cursive;
  font-weight: 400;
  font-style: normal;
}