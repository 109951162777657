.quote-section {
  height: 800px;
  background-color: #efe9db;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 0 20px;
  text-align: center;
}

.quote-logo {
  max-height: 60%;
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px;
}

.quote-text {
  font-family: 'Love Light', sans-serif; /* Use your preferred font */
  color: #333; /* Adjust the text color as needed */
  margin: 0;
  font-size: 56px;
}

@media (max-width: 768px) {
  .quote-section {
    padding: 30px 10px;
    height: 500px;
  }

  .quote-logo {
    max-height: 30%; /* Adjust size for smaller screens */
  }

  .quote-text {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }
}
