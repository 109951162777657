.sidebar {
    position: fixed;
    top: 0;
    left: -400px;
    width: 400px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: left 0.3s;
    z-index: 999;
    padding-top: 60px;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 50px;
    cursor: pointer;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
  }
  
  .sidebar ul li {
    padding: 15px 20px;
    cursor: pointer;
    font-size: 22px;
    color: white
  }
  