.hamburger-menu {
    color: white;
    position: fixed;
    top: 20px;
    left: 20px;
    font-size: 50px;
    cursor: pointer;
    z-index: 1000;
    transition: opacity 0.3s ease;
  }

  .hamburger-menu.hidden {
    opacity: 0;
    pointer-events: none; /* Prevents click events when hidden */
  }
  