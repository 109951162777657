.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../images/b-forest-1.jpg') no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-menu {
    display: flex;
    flex-direction: row;
    gap: 50px;
    font-size: 32px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .hero-menu button {
    background: none;
    border: none;
    padding: 0;
    font-size: 1.5em;
    cursor: pointer;
    color: white; /* Change this to match your desired text color */
    text-align: center;
  }
  
  /* Media Query for smaller screens */
@media (max-width: 768px) {
  .hero-menu {
    flex-direction: column;
    gap: 40px; /* Adjust gap as needed for vertical stacking */
  }
}