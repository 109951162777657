.corporate {
  display: flex;
  height: 100vh;
}

.corporate-image {
  width: 50%; /* Fixed width for desktop */
  background: url('../images/b-studio-2.jpg') no-repeat center center/cover;
  box-sizing: border-box;
}

.corporate-text-wrapper {
  width: 50%; /* Fixed width for desktop */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.corporate-header {
  font-size: 2em; /* Adjust font size as needed */
  margin-bottom: 20px; /* Space between header and text */
}

.corporate-text {
  padding: 10%; /* Percentage padding for better responsiveness */
  font-size: 1.5em; /* Large text */
  box-sizing: border-box;
}

.corporate-text p {
  margin: 0; /* Remove default margin */
  line-height: 1.6; /* Improve readability */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .corporate {
    flex-direction: column; /* Stack elements vertically */
    height: auto; /* Let the height adjust to content */
  }

  .corporate-image,
  .corporate-text-wrapper {
    width: 100%; /* Full width for mobile */
    height: 50vh; /* Adjust height as needed */
  }

  .corporate-image {
    height: 50vh; /* Ensure the image has a reasonable height */
  }

  .corporate-text-wrapper {
    height: auto; /* Let the text wrapper height adjust to content */
  }

  .corporate-text {
    font-size: 1.2em; /* Adjust font size for mobile */
  }
  .corporate-header {
    margin-top: 40px; /* Space between header and text */
  }
}
