.footer-section {
    background-color: #efe9db;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    font-size: 1.2em;
    min-height: 600px;
}

.footer-logo {
    max-height: 200px;
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 60px;
}

.footer-contact {
    margin: 20px 0;
    color: #000; /* Adjust the text color as needed */
}

.footer-social {
    display: flex;
    gap: 20px;
}

.footer-social a {
    color: #333; /* Adjust the color as needed */
    font-size: 2em;
    text-decoration: none;
}

.footer-social a:hover {
    color: #666; /* Add a hover effect */
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 100px;
    width: 100%;
    max-width: 1200px;
}

.footer-map {
    width: 50%;
    border: 0;
}

@media (max-width: 768px) {
    .footer-section {
        padding: 30px 10px;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-contact {
        font-size: 1em; /* Adjust font size for smaller screens */
    }

    .footer-social a {
        font-size: 1.5em; /* Adjust icon size for smaller screens */
    }

    .footer-map {
        max-width: 100%;
    }
}
